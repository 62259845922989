import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '@/api/lobby-game'
import { PATH_GAME_URL } from '~/constants/path'
import { EMPTY_CONTENT } from '~/constants/lobby'
import { useGameStore } from '~~/store/game'
import { useLoading } from '~/composables/useLoading'
import { IItemCongGame, IProvider } from '~/types/lobby.type'
export const useCasino = () => {
  const { $device, $pinia, $axios } = useNuxtApp()
  const store = useGameStore($pinia)
  const { LOBBY_CATEGORY, LIST_LOBBY_CASINO } = useLobbyGameApi()
  const route = useRoute()
  const router = useRouter()
  const { loading, load, isLoading } = useLoading()
  const { currentRoute, resultProvider, providerCasino } = storeToRefs(store)
  const listCasino = ref<IItemCongGame[] | null>(null)
  const listProvider = ref<IProvider[] | null>(null)
  const titleCasino = 'LIVE CASINO SIÊU HẠNG'
  const allObject = {
    code: 'all',
    name: 'Tất cả',
    url: '',
    img: `${PATH_GAME_URL}icon-all-mb.webp`
  }
  const providerMapping: [any: string] = {
    go: 'hit'
  }
  const reversedMapping = Object.fromEntries(Object.entries(providerMapping).map(([key, value]) => [value, key]))
  const currentProvider = ref<IProvider | null>(allObject)
  const currentCategory = ref<IItemCongGame | null>(null)
  const totalItemPerPage = ref<number>(0)
  const typeQuery = computed(() => route.params?.type as string)
  const partnerQuery = computed(() => route.query?.partner as string)
  const listCategoryCasino = ref<IItemCongGame[]>([])
  const totalItem = ref<number>(0)
  const placeholderCount = ref<number>(0)
  const isLoadingProvider = ref<boolean>(false)

  // View More
  const LIMIT: number = 30
  const page = ref<number>(1)
  const hasMoreGame = ref(false)
  const isLoadingMore = ref(false)
  const totalPage = ref(0)

  const initLoadData = async (isChangeProvider: boolean = true) => {
    page.value = 1
    isLoadingProvider.value = isChangeProvider
    await fetchListCasino()
    if (providerCasino.value) {
      if (isChangeProvider) {
        listProvider.value! = []
        filterProvider(providerCasino.value)
      }
      currentProvider.value =
        listProvider.value?.find((item: IProvider) => item?.code === partnerQuery.value) ||
        (listProvider.value ? listProvider.value[0] : null)
    }
    currentCategory.value =
      providerCasino.value.find((item: IItemCongGame) => item?.alias === typeQuery.value) || providerCasino.value[0]
    isLoadingProvider.value = false
  }
  const handleChangeCategory = (item: IItemCongGame) => {
    currentProvider.value = allObject
    const _query = { ...route.query }
    if (item?.alias || item?.link) {
      router.push(item?.alias ? item.alias : item.link)
    }
  }
  const handleChangeProvider = (item: IProvider) => {
    currentProvider.value = item
    if (item?.code) {
      const providerType = providerMapping[item?.code] || item?.code
      navigateTo({
        query: { ...route.query, partner: providerType }
      })
    }
  }
  const filterProvider = (data: IItemCongGame[]) => {
    const type = route?.params?.type as string
    const filteredItem = data.find((item) => {
      if (type) {
        return item?.alias === type
      }
      return item?.alias === 'all'
    })

    listProvider.value = filteredItem?.providers || []
    const isAllExists = listProvider.value.some((item) => item?.code === 'all')
    if (!isAllExists) {
      listProvider.value.unshift(allObject)
    }
  }
  const fetchListCasino = async () => {
    loading()
    const gameType = route?.params?.type
    totalItem.value = 0
    let partnerProvider = partnerQuery.value
    partnerProvider = reversedMapping[partnerProvider] || partnerProvider
    const queryUrl = queryString.stringify({
      path_alias: gameType,
      provider_code: partnerProvider && partnerProvider !== 'all' ? partnerProvider : '',
      limit: LIMIT,
      page: page.value
    })
    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_CASINO}?${queryUrl}`)
      if (response.status === 'OK') {
        if (page.value > 1) {
          listCasino.value = listCasino.value?.concat(response.data?.items) ?? [...response.data?.items]
        } else {
          listCasino.value = response.data?.items
        }
        totalPage.value = response.data.total_page
        hasMoreGame.value = page.value < response.data.total_page
        store.setGameLists(listCasino.value)
        if (typeQuery.value !== currentRoute.value) {
          store.setCurrentRoute(typeQuery.value)
        }
      }
    } catch (error: any) {
      console.log('fetchListCasino', error?.response?.data?.message)
    } finally {
      load()
    }
  }

  const calculatePlaceholder = () => {
    const totalItems = listCasino.value?.length || 0
    let itemsPerRow
    if ($device.isDesktop) {
      itemsPerRow = 6
    } else {
      itemsPerRow = 3
    }

    const remainder = totalItems % itemsPerRow
    placeholderCount.value = remainder === 0 ? 0 : itemsPerRow - remainder
  }
  watch(listCasino, () => {
    if (listCasino.value && listCasino.value.length > 0) {
      calculatePlaceholder()
    }
  })
  const fetchGameCasino = async () => {
    try {
      loading()
      const { data: response } = await $axios.get(`${LOBBY_CATEGORY}?alias=livecasino`)
      if (response.status === 'OK') {
        listCategoryCasino.value = response?.data || []
        store.setProviderCasino(response.data)
        filterProvider(listCategoryCasino.value)
        return response?.data || []
      }
    } catch (error: any) {
      console.log('fetchGameCasino', error?.response?.data?.message)
      return []
    } finally {
      load()
    }
  }

  const loadMoreListCasino = async () => {
    isLoadingMore.value = true
    page.value += 1
    await fetchListCasino()
    isLoadingMore.value = false
  }

  return {
    fetchListCasino,
    handleChangeProvider,
    initLoadData,
    listProvider,
    currentProvider,
    listCasino,
    isLoading,
    titleCasino,
    totalItem,
    totalItemPerPage,
    EMPTY_CONTENT,
    resultProvider,
    calculatePlaceholder,
    placeholderCount,
    currentCategory,
    typeQuery,
    partnerQuery,
    fetchGameCasino,
    listCategoryCasino,
    handleChangeCategory,
    page,
    reversedMapping,
    providerMapping,
    LIMIT,
    isLoadingProvider,
    hasMoreGame,
    isLoadingMore,
    loadMoreListCasino
  }
}
